









import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OAdditionalConfig: () =>
      import(
        "@/components/organisms/event/eventConfig/o-additional-config.vue"
      ),
  },
  setup() {
    return {};
  },
});
